$(document).ready(function () {

    $('#piechart1').resize(function () {
        donut1.redraw();
    });

    // $('.settings[data-toggle="popover"]').popover({
    //     placement: 'bottom',
    //     html: true,
    //     template: '<div class="popover l-popover--settings" role="tooltip"><div class="popover-body"></div></div>',
    //     content: $('#settings').html(),
    //     trigger: 'click',
    // });

    //LOGIN FORM VALIDATE
    $("#btnSubmit").click(function (event) {

        //Fetch form to apply custom Bootstrap validation
        var form = $("#myForm");
        if (form[0].checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
        }
        form.addClass('was-validated');
    });


    //TABLES
    var tableSettings = {
        "lengthMenu": [[3, 6, 9], [3, 6, 9]],
        "dom": 'rt<"d-flex justify-content-end c-pagination mt-3"<"c-pagination__results"l><"c-pagination__pages"p>><"clear">',
        "pagingType": "full_numbers",
        // "order": [[ 3, "desc" ]],
        "language": {
            "lengthMenu": "Show _MENU_ results per page",
            "paginate": {
                "previous": "",
                "first": "<",
                "next": ">",
            }
        },
    };
    var tableSettings1 = {
        "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
        "order": [],
        "dom": 'rt<""<"c-pagination__pages float-left"p><"d-flex justify-content-end c-pagination mt-3 pb-3"<"c-search"f><"c-pagination__results"l>>><"clear">',
        columnDefs: [
          { targets: 0, orderable: false, "width": "3%" },
          { targets: 1, "width": "4%" },
          { targets: 2, "width": "7%" }, //16
          { targets: 3, "width": "7%" },
          { targets: 4, "width": "7%" },
          { targets: 5, "width": "6%" }, //20
          { targets: 6, "width": "5%" },
          { targets: 7, "width": "6%" },
          { targets: 8, "width": "6%" }, //18
          { targets: 9, "width": "7%" },
          { targets: 10, "width": "7%" },
          { targets: 11, "width": "8%" }, //20
          { targets: 12, "width": "7%" },
          { targets: 13, "width": "7%" },
          { targets: 14, "width": "8%" },
          { targets: 15, "width": "5%", orderable: false } //26
        ],
        "createdRow": function (row, data, index) {
            $(row).find("input[type='checkbox']").off('click').on('click', function () {
                $(this).closest('tr').toggleClass('checked');
            });
        },
        "headerCallback": function (thead, data, start, end, display) {
            $(thead).find("input[type='checkbox']").eq(0).off('click').on('click', function () {
                var thead = $(this).closest('thead');

                var tr = $(this).closest("table").find('tbody tr');

                tr.find("input[type='checkbox']").each(function () {
                    if ($(thead).find("input[type='checkbox']").is(':checked')) {
                        $(this).prop('checked', true);
                        tr.addClass('checked');
                    } else {
                        $(this).prop('checked', false);
                        tr.removeClass('checked');
                    }
                });

            });
        }
    };
    var tableSettingsSearchAsset = {
        "lengthMenu": [[10, 25, 50, 100], [10, 25, 50, 100]],
        "dom": 'rt<"d-flex justify-content-end c-pagination mt-3"<"c-pagination__results"l><"c-pagination__pages"p>><"clear">',
        "pagingType": "full_numbers",
        "ordering": false,
        "language": {
            "lengthMenu": "Show _MENU_ results per page",
            "paginate": {
                "previous": "",
                "first": "<",
                "next": ">",
            }
        },
    };



    var table5 = $('#search-asset').DataTable(tableSettingsSearchAsset);
    setTimeout(function () {
        var table1 = $('#onRequest').DataTable(tableSettings);
        var table2 = $('#awaitingRequest').DataTable(tableSettings);
        var table3 = $('#zDiv').DataTable(tableSettings);
        var table6 = $('#detail-asset').DataTable(tableSettings);
        //var table4 = $('#reservation-history').DataTable(Object.assign(tableSettings, tableSettings1));

        table5.columns.adjust().draw();
    }, 10);


    //Show more rows;
    $('.js-btn').on('click', function () {
        var id = $(this).data('table');
        var VisibleRows = $('#' + id + '>tbody>tr:visible').length;
        var i = VisibleRows + 3;
        switch (id) {
            case 'onRequest':
                table1.page.len(i).draw();
                break;
            case 'awaitingRequest':
                table2.page.len(i).draw();
                break;
            case 'zDiv':
                table3.page.len(i).draw();
        }
    });

    //Tooltips
    $(function () {
        // $('[data-toggle="popover"]').popover();
        $('[data-toggle="tooltip"]').tooltip({ trigger: 'hover' });
    });


    //Remove collapse class from section for desktop screen
    // var collapsedSections = $('.js-xs-collapse');

    // function removeCollapseClass() {
    //     if (window.innerWidth < 768) {
    //         collapsedSections.each(function () {
    //             $(this).addClass('collapse');
    //         })
    //     } else {
    //         collapsedSections.each(function (section) {
    //             $(this).removeClass('collapse');
    //         })
    //     }
    // }
    // removeCollapseClass();

    //CHARTS
    if ($('#piechart1').length) {
        var piechart1 = 'piechart1';
        var donut1 = new Morris.Donut({
            element: 'piechart1',
            resize: true,
            data: [{
                label: "ZReauestNew",
                value: 20
            },
              {
                  label: "ZRequest",
                  value: 60
              },
              {
                  label: "DNA Sample Processing",
                  value: 4
              },
              {
                  label: "Quote Service Test",
                  value: 16
              }
            ],
            backgroundColor: '#fff',
            labelColor: '#060',
            colors: ["#DBECF8", "#2C82BE", "#53A8E2", "#76DDFB"],
            formatter: function (x) {
                return x + "%"
            }
        });

        donut1.select(3);

        donut1.options.data.forEach(function (label, i) {
            var legendItem = $('<span></span>').text(label['value'] + " " + label['label']).prepend('<i>&nbsp;</i>');
            legendItem.find('i').css('backgroundColor', donut1.options.colors[i]);
            $('#legend1').append(legendItem)
        });
    }

    if ($('#piechart2').length) {
        var donut2 = new Morris.Donut({
            element: 'piechart2',
            data: [{
                label: "DNA Sample Processing",
                value: 100
            }],
            backgroundColor: '#fff',
            labelColor: '#060',
            colors: ["#2C82BE"],
        });
        donut2.options.data.forEach(function (label, i) {
            var legendItem = $('<span></span>').text(label['value'] + " " + label['label']).prepend('<i>&nbsp;</i>');
            legendItem.find('i').css('backgroundColor', donut2.options.colors[i]);
            $('#legend2').append(legendItem)
        })
    }
    function donutResponsive() {
        $('#aw-req-steps').on('shown.bs.collapse', function () {
            donut1.redraw();
        });
        $('#on-req-steps').on('shown.bs.collapse', function () {
            donut2.redraw();
        });
    }
    donutResponsive();



    var onResize = function () {
        //removeCollapseClass();
        donutResponsive();
    };

    $('#dropdown-reservation-status').off('click').on('click', function (ev) {
        var target = $(ev.target);
        var currentTarget = $(ev.currentTarget);
        if (target.is('a')) {
            currentTarget.find('button').removeClass('btn-filter--icon').html(target.html());
        }
    });
    $('#dropdown-schedule-status').off('click').on('click', function (ev) {
        var target = $(ev.target);
        var currentTarget = $(ev.currentTarget);
        var icon = currentTarget.find('button > span');
        if (target.is('a')) {
            icon.addClass('mr-3 h-icon-color');
            currentTarget.find('button').removeClass('btn-filter--icon').html(function () {
                return icon[0].outerHTML + target.html();
            });
        }
    });

    $('.select2').select2({
        placeholder: 'Select an option',
        containerCssClass: 'btklab-select',
        dropdownCssClass: 'btklab-select-dropdown',
        width: '100%'
    });
    // $.fn.datepicker.defaults.format = 'mm/dd/yyyy';
    // $.fn.datepicker.defaults.orientation = 'bottom';
    // $.fn.datepicker.defaults.todayHighlight = 'true';
    //$('.date').datetimepicker({
    //  format: 'mm/dd/yyyy',
    //  startView: 2,
    //  minView: 2
    //});
    //$('.time').datetimepicker({
    //  format: 'HH:mm P',
    //  startView: 1
    //});

    //Email modal

    $('#emailModal').on('show.bs.modal', function (event) {

        var modal = $(this);

        //Fetch form to apply custom Bootstrap validation
        var form = modal.find("#sendEmail");

        modal.find('[data-btn="send"]').off('click').on('click', function () {
            if (form[0].checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
                modal.find('.invalid-feedback').addClass('d-flex');
            } else {
                modal.find('[data-step="2"]').removeClass('d-none');
                modal.find('[data-step="1"]').addClass('d-none');
            }
            form.addClass('was-validated');

        });
    });

    $('#emailModal').on('hide.bs.modal', function (event) {
        var modal = $(this);
        modal.find('[data-step="2"]').addClass('d-none');
        modal.find('[data-step="1"]').removeClass('d-none');
        modal.find("#sendEmail").removeClass('was-validated');
    });

    //Show until day only when weekly reservation turn on
    $('[data-id="weekly-reservation"] input').on('change', function (ev) {
        $('#weekly-reservation').toggleClass('d-none');
    })


    //Add error message to new reservation modal
    $('#reservationDetail').find('[data-id="credit"]').on('click', function () {
        $('#reservationDetail').find('.invalid-feedback').toggleClass('d-flex');
    })

    $('#new-reservation-content').find('[data-id="credit"]').on('click', function () {
        $('#new-reservation-content').find('.invalid-feedback').toggleClass('d-flex');
    });



    //SHow filters

    $('[data-show="filters"]').click(function () {
        $('#filters').toggleClass('d-none');
    });

    $(document).ready(onResize); // Calling onResize function
    $(window).bind('resize', onResize); // Binding to the resize event
});

//webshim datepicker arrows click
function Step(ancor) {
    if ($(ancor).hasClass('step-up-date')) {
        var parent = $(ancor).parent().parent();
        var input = $(parent).find(':input[type=date]');
        $(input).stepUp();
    }
    else if ($(ancor).hasClass('step-down-date')) {
        var parent = $(ancor).parent().parent();
        var input = $(parent).find(':input[type=date]');
        $(input).stepDown();
    }
    else if ($(ancor).hasClass('step-up-time')) {
        var parent = $(ancor).parent().parent();
        var input = $(parent).find(':input[type=time]');
        $(input).stepUp();
    }
    else if ($(ancor).hasClass('step-down-time')) {
        var parent = $(ancor).parent().parent();
        var input = $(parent).find(':input[type=time]');
        $(input).stepDown();
    }
}
//webshim datepicker arrows setup
$(document).ready(function () {
    var arrows = $('.arrows');
    for (var i = 0; i < arrows.length; i++) {
        //var btn = $(arrows[i]).find('.ws-popover-opener');
        //$(btn).hide();
        //var span = $(arrows[i]).find('.step-controls');
        //$(span).hide();
        //var input = $(arrows[i]).find('.ws-inputreplace').css('border-right', 'none');
        var spanBtn = $(arrows[i]).find('.input-buttons');
        $(spanBtn).remove();

    }
});
$(document).ready(function () {
	//function pad(n) {
	//	return n < 10 ? '0' + n : n;
	//}
	var DatePik = $(':input[type=date]');
	if (DatePik.length > 0) {
		$.each(DatePik, function (i, v) {
			var dateVal = $(v).val();
			//var val = $(dateVal).val();
			if (dateVal === "") {
				$(v).on('focus', function () {
					var pic = $('.ws-popover[data-id=' + $(this).prop('id') + ']');
					setTimeout(function () { $(pic).find('td button.this-value').focus(); }, 200);
				});
				
				//var currentDate = new Date();

				//var date = currentDate.getDate();
				//var month = currentDate.getMonth(); //Be careful! January is 0 not 1
				//var year = currentDate.getFullYear();

				//var dateString = year + "-" + pad(month + 1) + "-" + pad(date);//pad(date) + "-" + pad(month + 1) + "-" + year;
				//$(v).val(dateString);
			}
		});
	}
	
});
// Custom Bootstrap File Input handler
$(function () {
    $('.custom-file-input').on('change', function () {
        //get the file name
        var fieldVal = $(this).val();
        // Change the node's value by removing the fake path (Chrome)
        fieldVal = fieldVal.replace("C:\\fakepath\\", "");
        //replace the "Choose a file" label
        var next = $(this).prev('.custom-file-label');
        var prev = $(this).prev('.custom-file-label');
        if (next.length > 0) {
            $(next).attr('data-content', fieldVal);
            $(next).text(fieldVal);
        } else {
            $(prev).attr('data-content', fieldVal);
            $(prev).text(fieldVal);
        }

    });
});
// Switcher of SVG icons for panels
$(function () {
    $('[data-show="collapse-panel"]').on('click', function () {
        var curr = $(event.currentTarget).find('svg');
        var parent = $(event.currentTarget).parent().parent();
        var cls = $(parent).hasClass('d-full');
        if ($(parent).hasClass('d-full')) {
            $(curr).html('<use xlink:href="../bookitStyle/dist/svg-sprites/fa-solid.svg#expand-alt" />');
            $(parent).removeClass('d-full');
        }
        else {
            $(curr).html('<use xlink:href="../bookitStyle/dist/svg-sprites/fa-solid.svg#compress-alt" />');
            $(parent).addClass('d-full');
        }
    });
});

// Get Date from inputs
function getDateFromInputControl(dateInput, timeInput) {
    var date = $(dateInput).val();
    if (date) {
        date = new Date(dateInput.val());
        var time = Date.parse(timeInput.val());
        if (date && time) {
            var hour = time.getHours();
            date.setHours(hour);
            var min = time.getMinutes();
            date.setMinutes(min);
            return date;
        } else {
            return date;
        }
    }
    return null;
}
function getDateFromInput(dateInput) {
    var date = $(dateInput).val();
    if (date) {
        date = new Date(dateInput.val());
        return date;
    }
    return null;
}